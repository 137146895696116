import { QueryCache, type QueryClientConfig } from '@tanstack/react-query'
import { globalQueryErrorHandler } from 'src/data/queries/utils'

export const SERVER_GC_TIME = 10000 // 10 seconds
const DEFAULT_STALE_TIME = 60000 // 1 minute

export const queryClientConfigFactory = (
  {
    staleTime = DEFAULT_STALE_TIME,
    gcTime = Infinity,
  }: {
    staleTime?: number
    gcTime?: number
  } = { staleTime: DEFAULT_STALE_TIME, gcTime: Infinity }
): QueryClientConfig => ({
  queryCache: new QueryCache({
    onError: globalQueryErrorHandler,
  }),

  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime,
      gcTime,
    },
  },
})

export * from './tickets'
export * from './contracts'
export * from './contracts-v3'
export * from './commodity-balances'
export * from './custom-entities'
export * from './delivery-tickets'
export * from './esign'
export * from './elevators'
export * from './settlements'
export * from './prepaid-contracts'
export * from './users'
export * from './utils'
export * from './delivery-tickets'
export * from './invoices'
export * from './payments'
export * from './work-orders'
export * from 'src/data/queries/reports'
export * from './business-locations'
