import React, { useMemo } from 'react'
import {
  ChakraModal,
  ChakraModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from 'src/components/designsystem'
import { BackButtonBehavior } from 'src/components/resource/BackButtonBehavior'
import BackButton from 'src/components/shared/BackButton'
import getClient from 'src/utils/clients/get-client'

type FloatingModalProps = {
  onBack?: () => void
  onDismiss: () => void
  header: React.ReactNode
  body: React.ReactNode
  footer: React.ReactNode
}

// This name is very similar to StackingFloatingModal.
// Investigate: https://bushel.atlassian.net/browse/AGB-7448
export function FloatingModal({
  onBack,
  onDismiss,
  header,
  body,
  footer,
}: Readonly<FloatingModalProps>) {
  const isAndroid = getClient().isAndroid
  const backStackItems = useMemo(() => [{ callback: onBack }], [onBack])

  return (
    <>
      {isAndroid && <BackButtonBehavior backStackItems={backStackItems} />}

      <ChakraModal
        onClose={onDismiss}
        isOpen
        isCentered
        size="md"
        scrollBehavior="inside"
        motionPreset="none"
      >
        <ModalOverlay />

        <ModalContent>
          <ChakraModalHeader
            marginTop="env(safe-area-inset-top)"
            paddingX={6}
            paddingTop={4}
            paddingBottom={0}
          >
            <ModalCloseButton top={4} right={4} />
            {onBack && <BackButton onClick={onBack} paddingX={0} paddingBottom={8} margin={0} />}
            {header}
          </ChakraModalHeader>

          <ModalBody maxHeight="16rem" paddingX={6} paddingY={0}>
            {body}
          </ModalBody>

          <ModalFooter paddingTop={10}>{footer}</ModalFooter>
        </ModalContent>
      </ChakraModal>
    </>
  )
}
