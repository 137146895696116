import { createIcon, IconButton, IconButtonProps } from '@chakra-ui/react'

const DebugIcon = createIcon({
  defaultProps: {
    'aria-hidden': true,
  },
  displayName: 'DebugIcon',
  viewBox: '0 0 512 512',
  path: (
    <>
      <path
        d="M370 378c28.89 23.52 46 46.07 46 86m-274-86c-28.89 23.52-46 46.06-46 86m288-256c28.89-23.52 32-56.07 32-96m-288 94c-28.89-23.52-32-54.06-32-94m368 176.13h-80m-256 0H48M256 192v256"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="32"
      />
      <path
        d="M256 448h0c-70.4 0-128-57.6-128-128v-96.07c0-65.07 57.6-96 128-96h0c70.4 0 128 25.6 128 96V320c0 70.4-57.6 128-128 128z"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="32"
      />
      <path
        d="M179.43 143.52a49.08 49.08 0 01-3.43-15.73A80 80 0 01255.79 48h.42A80 80 0 01336 127.79a41.91 41.91 0 01-3.12 14.3"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="32"
      />
    </>
  ),
})

export function DebugIconButton(props: Readonly<Partial<IconButtonProps>>) {
  return (
    <IconButton
      variant="ghostDestructive"
      aria-label="Debug Drawer"
      icon={<DebugIcon />}
      fontSize="2xl"
      boxSize={8}
      isRound
      {...props}
    />
  )
}
