import { HStack, IconButton, Select } from '@chakra-ui/react'
import { DatepickerResult } from 'src/components/designsystem/date-picker/hooks'
import { ChevronBackwardIcon, ChevronForwardIcon } from 'src/components/designsystem/Icons'
import dayjs from 'dayjs'

interface HeaderProps {
  monthOptions?: string[]
  yearOptions?: number[]
  previousMonthProps: DatepickerResult['previousMonthProps']
  nextMonthProps: DatepickerResult['nextMonthProps']
  monthSelectProps: DatepickerResult['monthSelectProps']
  yearSelectProps: DatepickerResult['yearSelectProps']
}

export default function Header({
  monthOptions = defaultMonths,
  yearOptions = defaultYears,
  previousMonthProps,
  nextMonthProps,
  monthSelectProps,
  yearSelectProps,
}: HeaderProps) {
  return (
    <HStack py={4} px={6} justify="space-around" w="100%">
      <IconButton
        aria-label="Previous Month"
        icon={<ChevronBackwardIcon />}
        variant="secondary"
        bgColor="transparent"
        size="xs"
        isRound
        {...previousMonthProps}
      />
      <HStack>
        <Select
          flex={1.5}
          size="sm"
          borderRadius="8px"
          aria-label="Current Month"
          {...monthSelectProps}
        >
          {monthOptions.map((month, index) => {
            return (
              <option key={month} value={index}>
                {month}
              </option>
            )
          })}
        </Select>
        <Select
          flex={1}
          size="sm"
          borderRadius="8px"
          aria-label="Current Year"
          {...yearSelectProps}
        >
          {yearOptions.map((year) => {
            return (
              <option key={year} value={year}>
                {year}
              </option>
            )
          })}
        </Select>
      </HStack>
      <IconButton
        aria-label="Next Month"
        icon={<ChevronForwardIcon />}
        variant="secondary"
        bgColor="transparent"
        size="xs"
        isRound
        {...nextMonthProps}
      />
    </HStack>
  )
}

const defaultMonths = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

const defaultYears = getDefaultFilterYears()

function getDefaultFilterYears() {
  const years = []
  for (let i = 2010; i <= dayjs().year() + 5; i++) {
    years.push(i)
  }
  return years
}
