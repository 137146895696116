import {
  IconButton,
  SettingsActiveIcon,
  SettingsStandardIcon,
  Tooltip,
} from 'src/components/designsystem'
import { useAuth } from 'src/auth'
import { useIsStaffPage } from 'src/utils'
import { useRouter } from 'next/router'
import { useRightSidebarStore } from 'src/components/layout/right-sidebar/right-sidebar-store'

export default function SettingsHeaderButton() {
  const { slug } = useAuth()
  const isStaffPage = useIsStaffPage()
  const { push, asPath } = useRouter()
  const { closeRightSidebar } = useRightSidebarStore()

  const settingsRoute = ['', slug, ...(isStaffPage ? ['staff'] : []), 'settings'].join('/')
  const isSettingsPageActive = asPath.startsWith(settingsRoute)

  const handleClick = () => {
    closeRightSidebar()
    push(settingsRoute)
  }

  return (
    <Tooltip label="Settings" hasArrow>
      <IconButton
        onClick={handleClick}
        icon={
          isSettingsPageActive ? (
            <SettingsActiveIcon color="blue.400" />
          ) : (
            <SettingsStandardIcon color="gray.500" />
          )
        }
        aria-label="Settings"
        data-testid="settings-button"
        aria-current={isSettingsPageActive ? 'page' : undefined}
        variant="menuIcon"
        fontSize="2xl"
        isRound
        boxSize={8}
      />
    </Tooltip>
  )
}
