import { AuthzPermission } from 'src/api/bidl/iam/authz-permissions'
import { useTenantId } from 'src/data/queries/config'
import { type LookupResourcesQueryOptions, useLookupResources } from './useLookupResources'

export default function useHasAuthzPermission(
  permission: AuthzPermission,
  options: Partial<LookupResourcesQueryOptions<boolean>> = {}
) {
  const { data: tenantId } = useTenantId()

  return useLookupResources(
    { permissions: [permission], tenantId },
    {
      ...options,
      select: (data) =>
        data.results
          .filter((result) => {
            return (
              result.root ||
              result.tenants.some((tenant) => tenant.tenantId === tenantId) ||
              result.accounts.length > 0 ||
              result.locations.length > 0
            )
          })
          .some((result) => result.permission === permission),
    }
  )
}
