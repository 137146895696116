import { useCallback } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import create from 'zustand'
import { combine } from 'zustand/middleware'
import { useGetAccountsByUserId, useUserSearch } from 'src/data/queries'
import { trackEvent } from 'src/utils/analytics'
import * as api from 'src/api'

const useSearch = create(
  combine(
    // Init state
    { isSearching: false },

    // Actions
    (set, _get) => ({
      openSearch: () => {
        set({ isSearching: true })
        trackEvent('Merchandiser', 'Start Search')
      },
      closeSearch: () => {
        set({ isSearching: false })
        trackEvent('Merchandiser', 'End Search')
      },
    })
  )
)

///////////////////////////////////////////////////////////////////////////////////////////////////

export function useMerchandiser() {
  const { pathname, query, push } = useRouter()
  const queryClient = useQueryClient()
  const { isSearching, openSearch, closeSearch } = useSearch((state) => state)

  const { company, userId, feature } = {
    company: undefined,
    userId: undefined,
    feature: pathname.replace('/[company]/', '').replace('[userId]/', ''),
    // Actual query params should override ^^ defaults
    ...query,
  }
  const selectedUserQuery = useUserSearch({ userId, enabled: !!userId })
  const selectedUser = getSelectedUser(selectedUserQuery)
  const selectedAccountsQuery = useGetAccountsByUserId({ userId })

  return {
    userId,
    selectedUserId: userId,
    selectedUser,
    isUnknownId: selectedUserQuery.isSuccess && !selectedUser,
    hasSelectedUser: !!selectedUser,
    selectedUserIdpId: selectedUser?.idp_id ?? selectedUser?.phone,
    isSearching,
    openSearch,
    closeSearch,

    selectedAccounts: selectedAccountsQuery.data ?? [],

    handleSelectUser: useCallback(
      (searchUser: SearchUser, specifiedFeature?: string) => {
        api.clearCentreAppHeaderImpersonation()

        if (searchUser.userId) {
          api.updateCentreAppHeader('X-Impersonate-Id', String(searchUser.userId))
          api.updatePaymentsServiceHeader('X-Impersonate-Id', String(searchUser.userId))
        }

        // Preload react-query cache with this user's data
        queryClient.setQueryData(makeUserQueryKey(searchUser), { data: [searchUser] })
        updateUrlForSelectUser({
          push,
          company,
          id: searchUser.userId,
          feature: specifiedFeature ?? feature,
        })
        trackEvent('Merchandiser', 'Select User')
        closeSearch()
      },
      [queryClient, push, company, feature, closeSearch]
    ),

    handleClearUser: useCallback(() => {
      api.clearCentreAppHeaderImpersonation()
      api.clearPaymentsServiceHeaderImpersonation()
      updateUrlForClearUser({ push, company, feature })
      trackEvent('Merchandiser', 'Clear User')
    }, [push, company, feature]),

    selectedUserQuery: selectedUserQuery,
    selectedAccountsQuery,
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////

function makeUserQueryKey({ userId }: SearchUser) {
  return ['user-search', { userId: String(userId) }]
}

function getSelectedUser(query: ReturnType<typeof useUserSearch>) {
  return query?.data?.data?.[0] || null
}

function updateUrlForSelectUser({ push, company, id, feature }) {
  push(['', company, encodeURIComponent(id), feature].join('/'), undefined, {
    shallow: true,
  })
}

function updateUrlForClearUser({ push, company, feature }) {
  push(['', company, feature].join('/'))
}
