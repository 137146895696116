import { ReactNode } from 'react'
import { AuthzPermission } from 'src/api/bidl/iam/authz-permissions'
import { useAuth } from 'src/auth'
import { useHasAuthzPermission } from 'src/data/queries/authorization'
import { ErrorState, LoadingState } from 'src/components/resource'
import { ClientRedirect } from 'src/utils/navigation'
import { CONFIGCAT_FLAGS, configCatSSRClient } from 'src/utils/config-cat'

export default function AuthzPermissionCheck({
  permission,
  children,
}: Readonly<{ permission: AuthzPermission; children: ReactNode }>) {
  const { authenticated, slug, isStaff } = useAuth()

  // TODO: Remove once authz permission checks are enabled for all users
  const configCatSnapshot = configCatSSRClient.snapshot()
  const isPermissionCheckEnabled = configCatSnapshot.getValue(
    isStaff
      ? CONFIGCAT_FLAGS.isAuthzPermissionsEnabled
      : CONFIGCAT_FLAGS.isCustomerAuthzPermissionsEnabled,
    false
  )

  const { data: hasAuthzPermission, isLoading } = useHasAuthzPermission(permission, {
    enabled: isPermissionCheckEnabled,
  })

  if (!isPermissionCheckEnabled) {
    return children
  }

  if (isLoading) {
    return <LoadingState mt="15vh" />
  }

  if (!hasAuthzPermission) {
    if (!authenticated) {
      return <ClientRedirect path={['', slug, 'auth', 'required'].join('/')} />
    }

    return (
      <ErrorState
        isVerticallyCentered
        header="Ope!"
        subHeader={
          <>
            Sorry, looks like you hit a dead end.
            <br />
            Refresh or request access.
          </>
        }
      />
    )
  }

  return children
}
