import type { ComponentStyleConfig } from '@chakra-ui/theme'
import { StyleFunctionProps } from '@chakra-ui/theme-tools'

function sizeOverrides({ variant }: StyleFunctionProps) {
  return {
    fontSize: '1rem',
    minW: variant !== 'link' ? 'auto' : null,
    px: '16px',
  }
}

export const ButtonStyleConfig: ComponentStyleConfig = {
  defaultProps: {
    size: 'md',
    variant: 'primary',
  },

  baseStyle: {
    lineHeight: '1rem',
    borderRadius: 'base',
    letterSpacing: '0.01em',
  },

  sizes: {
    sm: sizeOverrides,
    md: sizeOverrides,
    lg: sizeOverrides,
  },

  variants: {
    menuIcon: {
      color: 'gray.700',
      _hover: {
        shadow: 'xs',
        bg: 'white',
      },
      _focus: {
        shadow: 'sm',
        bg: 'grey.100',
      },
    },
    // SOLID: PRIMARY
    primary: {
      bg: 'blue.400',
      color: 'white',

      _hover: {
        bg: 'blue.500',
        _disabled: {
          bg: 'blue.400',
        },
      },
      _focus: {
        bg: 'blue.600',
        _disabled: {
          bg: 'blue.400',
        },
      },
      _disabled: {
        bg: 'blue.400',
        opacity: '0.4',
      },
    },

    // SOLID: SECONDARY
    secondary: {
      bg: 'gray.100',
      color: 'gray.700',

      _hover: {
        bg: 'gray.200',
        _disabled: {
          bg: 'gray.100',
        },
      },
      _focus: {
        bg: 'gray.300',
        _disabled: {
          bg: 'gray.100',
        },
      },
      _disabled: {
        bg: 'gray.100',
        opacity: '0.4',
      },
    },

    // OUTLINE
    outline: {
      bg: 'transparent',
      color: 'blue.400',
      border: '1px solid',
      borderColor: 'blue.400',

      _hover: {
        bg: 'blue.50',
        _disabled: {
          bg: 'transparent',
        },
      },
      _focus: {
        bg: 'blue.100',
        _disabled: {
          bg: 'transparent',
        },
      },
      _active: {
        bg: 'blue.50',
      },
      _disabled: {
        bg: 'transparent',
        opacity: '0.4',
      },
    },

    // GHOST
    ghost: {
      bg: 'transparent',
      color: 'blue.400',

      _hover: {
        bg: 'blue.50',
        _disabled: {
          bg: 'transparent',
        },
      },
      _focus: {
        bg: 'blue.100',
        _disabled: {
          bg: 'transparent',
        },
      },
      _active: {
        bg: 'blue.50',
      },
      _disabled: {
        bg: 'transparent',
        opacity: '0.4',
      },
    },

    ghostSecondary: {
      bg: 'transparent',
      color: 'gray.700',

      _hover: {
        bg: 'gray.200',
        _disabled: {
          bg: 'transparent',
        },
      },
      _focus: {
        bg: 'gray.300',
        _disabled: {
          bg: 'transparent',
        },
      },
      _disabled: {
        bg: 'transparent',
        opacity: '0.4',
      },
    },

    ghostDestructive: {
      bg: 'transparent',
      color: 'red.500',

      _hover: {
        bg: 'red.50',
        _disabled: {
          bg: 'transparent',
        },
      },
      _focus: {
        bg: 'red.100',
        _disabled: {
          bg: 'transparent',
        },
      },
      _active: {
        bg: 'red.50',
      },
      _disabled: {
        bg: 'transparent',
        opacity: '0.4',
      },
    },

    // DESTRUCTIVE
    destructive: {
      bg: 'red.500',
      color: 'white',

      _hover: {
        bg: 'red.600',
        _disabled: {
          bg: 'red.500',
        },
      },
      _focus: {
        bg: 'red.700',
        _disabled: {
          bg: 'red.500',
        },
      },
      _disabled: {
        bg: 'red.500',
        opacity: '0.4',
      },
    },
    // LINK
    link: {
      bg: 'none',
      color: 'blue.400',
      _hover: {
        color: 'blue.500',
        textDecoration: 'none',
      },
      _focus: {
        color: 'blue.600',
      },
      _disabled: {
        opacity: '40%',
        _hover: {
          color: 'blue.400',
          opacity: '40%',
        },
      },
      _active: {
        color: 'blue.600',
      },
    },
  },
}
