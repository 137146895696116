// https://dev.azure.com/bushelpowered/bushel-identity-authorization/_git/bushel-identity-authorization-service?path=/model/src/main/kotlin/com/bushelpowered/iam/authorization/model/Model.kt&version=GBmain&_a=contents
export const AUTHZ_PERMISSIONS = {
  ACCOUNT_PAYABLE_BALANCE_VIEW: 'account_payable_balance_view',
  ACCOUNT_VIEW: 'account_view',
  ACCOUNT_MANAGE: 'account_manage',
  ACCOUNT_WRITE: 'account_write',
  BOOKING_VIEW: 'booking_view',
  CASH_BID_MANAGE: 'cash_bid_manage',
  CASH_BID_VIEW: 'cash_bid_view',
  CUSTOM_ENTITY_VIEW: 'custom_entity_view',
  CUSTOMER_DEMOGRAPHIC_VIEW: 'customer_demographic_view',
  CUSTOMER_DEMOGRAPHIC_MANAGE: 'customer_demographic_manage',
  CUSTOMER_VIEW: 'customer_view',
  CUSTOMER_MANAGE: 'customer_manage',
  CUSTOMER_NOTE_VIEW: 'customer_note_view',
  CUSTOMER_NOTE_MANAGE: 'customer_note_manage',
  COMMODITY_BALANCE_VIEW: 'commodity_balance_view',
  CUSTOM_WEB_LINK_VIEW: 'custom_web_link_view',
  DELIVERY_TICKET_VIEW: 'delivery_ticket_view',
  // DISCOUNT_SCHEDULES_VIEW: 'discount_schedules_view',
  // DISCOUNT_SCHEDULES_MANAGE: 'discount_schedules_manage',
  ESIGN_MANAGE: 'esign_manage',
  ESIGN_SIGN: 'esign_sign',
  ESIGN_VIEW: 'esign_view',
  EXTERNAL_PAYMENTS_VIEW: 'external_payments_view',
  FARM_FIELD_MANAGE: 'farm_field_manage',
  FARM_FIELD_VIEW: 'farm_field_view',
  GRAIN_CONTRACT_VIEW: 'grain_contract_view',
  GRAIN_TICKET_VIEW: 'grain_ticket_view',
  IAM_DEBUG_EFFECTIVE_RESOURCES: 'iam_debug_effective_resources',
  IAM_DEBUG_LOOKUP_RESOURCES: 'iam_debug_lookup_resources',
  IAM_LOOKUP_RESOURCES_FOR_SUBJECT: 'iam_lookup_resources_for_subject',
  IAM_RELATIONSHIP_MANAGE: 'iam_relationship_manage',
  IAM_ROLE_BINDING_MANAGE: 'iam_role_binding_manage',
  IAM_ROLE_BINDING_VIEW: 'iam_role_binding_view',
  IAM_ROLE_MANAGE: 'iam_role_manage',
  IAM_ROLE_VIEW: 'iam_role_view',
  IAM_SUBJECT_GROUP_MANAGE: 'iam_subject_group_manage',
  IAM_SUBJECT_GROUP_VIEW: 'iam_subject_group_view',
  IAM_TENANT_AUTHORIZATION_CONFIGURATION_MANAGE: 'iam_tenant_authorization_configuration_manage',
  IAM_TENANT_AUTHORIZATION_CONFIGURATION_VIEW: 'iam_tenant_authorization_configuration_view',
  INVOICE_VIEW: 'invoice_view',
  NOTIFICATION_MANAGE: 'notification_manage',
  NOTIFICATION_VIEW: 'notification_view',
  OFFER_MANAGE: 'offer_manage',
  OFFER_VIEW: 'offer_view',
  OUTBOUND_TICKET_VIEW: 'outbound_ticket_view',
  STAFF_PAYMENT_INBOUND_VIEW: 'staff_payment_inbound_view',
  STAFF_PAYMENT_OUTBOUND_VIEW: 'staff_payment_outbound_view',
  PAYMENT_SEND: 'payment_send',
  REPORT_VIEW: 'report_view',
  SETTLEMENT_VIEW: 'settlement_view',
  STATEMENT_VIEW: 'statement_view',
  TENANT_COMMODITY_MANAGE: 'tenant_commodity_manage',
  TENANT_COMMODITY_VIEW: 'tenant_commodity_view',
  TENANT_MANAGE: 'tenant_manage',
  USER_VIEW: 'user_view',
  WALLET_LINK: 'wallet_link',
  BUSINESS_LOCATION_VIEW: 'business_location_view',
  BUSINESS_LOCATION_MANAGE: 'business_location_manage',
  WEB_AGGREGATOR_CONFIGURATION_VIEW: 'web_aggregator_configuration_view',
  WEB_AGGREGATOR_CONFIGURATION_MANAGE: 'web_aggregator_configuration_manage',
  WORK_ORDER_VIEW: 'work_order_view',
} as const

// TODO: Remove once isAuthzPermissionsEnabled ConfigCat flag is removed
export const AUTHZ_PERMISSIONS_TO_CENTRE_ROLES = {
  // The data_viewer permission doesn't exist in authz, but can be used if the config flag is disabled, to map to the role of the same name
  data_viewer: ['data_viewer'],
  report_view: ['admin', 'reporting'],
  staff_payment_inbound_view: ['payments_accounts_receivable'],
  staff_payment_outbound_view: ['payments_accounts_payable'],
  customer_view: ['crm', 'view_crm', 'data_viewer'],
  customer_manage: ['crm'],
  customer_demographic_manage: ['crm'],
  business_location_manage: ['business_location'],
} as const

export const allAuthzPermissions = Object.values(AUTHZ_PERMISSIONS)

export type AuthzPermission = (typeof allAuthzPermissions)[number]

/**
 * List of (server) prefetched permissions used for determining user authorization.
 *
 * Examples:
 * - Server-rendered UI, such as the navigation sidebar feature items
 * - Feature page-level access (without having to show an unnecessary loading state)
 */
export const prefetchedAuthzPermissions = [
  AUTHZ_PERMISSIONS.CUSTOMER_VIEW,
  AUTHZ_PERMISSIONS.REPORT_VIEW,
  AUTHZ_PERMISSIONS.BUSINESS_LOCATION_MANAGE,
  AUTHZ_PERMISSIONS.STAFF_PAYMENT_OUTBOUND_VIEW,
  AUTHZ_PERMISSIONS.STAFF_PAYMENT_INBOUND_VIEW,
] satisfies AuthzPermission[]

export type PrefetchedAuthzPermission = (typeof prefetchedAuthzPermissions)[number]
