import { prefetchedAuthzPermissions } from 'src/api/bidl/iam/authz-permissions'
import { useTenantId } from 'src/data/queries/config'
import { type LookupResourcesQueryOptions, useLookupResources } from './useLookupResources'

export default function usePrefetchedPermissions(
  options: Partial<LookupResourcesQueryOptions<string[]>> = {}
) {
  const { data: tenantId } = useTenantId()

  return useLookupResources<string[]>(
    { permissions: prefetchedAuthzPermissions, tenantId },
    {
      ...options,
      select: (data) =>
        data.results
          .filter((result) => {
            return (
              result.root ||
              result.tenants.some((tenant) => tenant.tenantId === tenantId) ||
              result.accounts.length > 0 ||
              result.locations.length > 0
            )
          })
          .map((result) => result.permission),
    }
  )
}
