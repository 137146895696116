import { PropsWithChildren, createContext, useContext, useMemo, useState } from 'react'
import {
  ButtonProps,
  IconButton,
  ChevronDownIcon,
  ChevronUpIcon,
} from 'src/components/designsystem'

interface ToggleState {
  toggled: boolean
  toggle: () => void
}

const ToggleContext = createContext<ToggleState>(undefined)

const ToggleProvider = ({ children }) => {
  const [isToggled, setIsToggled] = useState<boolean | undefined>(undefined)

  const toggleContext = useMemo(
    () => ({
      toggled: isToggled,
      toggle: () => setIsToggled(!isToggled),
    }),
    [isToggled, setIsToggled]
  )

  return <ToggleContext.Provider value={toggleContext}>{children}</ToggleContext.Provider>
}

const ToggleView = ({
  isExpanded,
  children,
}: {
  isExpanded?: boolean
  children: React.ReactNode
}) => <ToggleProvider {...{ isExpanded }}>{children}</ToggleProvider>

function ToggleViewContent({ inverted, children }: PropsWithChildren<{ inverted?: boolean }>) {
  const { toggled } = useContext(ToggleContext)
  let isVisible = toggled
  isVisible = inverted ? !isVisible : isVisible
  return isVisible ? <>{children}</> : null
}

const ToggleViewToggle = ({
  isLoading,
  isExpanded,
  ...props
}: {
  isLoading?: boolean
  isExpanded: boolean
} & ButtonProps) => {
  // const { toggled, toggle } = useContext(ToggleContext)

  return (
    <IconButton
      aria-label="Toggle"
      icon={isExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
      size="xs"
      isLoading={isLoading}
      variant="secondary"
      bgColor="transparent"
      borderRadius="full"
      fontSize="xl"
      w={6}
      minW="auto"
      isRound
      {...props}
    />
  )
}

ToggleView.Toggle = ToggleViewToggle
ToggleView.Content = ToggleViewContent

export default ToggleView

export const useToggle = ({ initialValue = false } = {}) => {
  const [isExpanded, setExpanded] = useState(() => initialValue || false)

  return {
    isExpanded,
    setExpanded,
  }
}
