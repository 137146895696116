import Link from 'next/link'
import { Button, ButtonProps } from 'src/components/designsystem'
import { SidebarItem as SidebarItemType } from 'src/components/layout/sidebar/helpers'
import { SidebarItemButtonCell } from './SidebarItemCell'
import { inAppBrowser } from 'src/utils/clients/native/in-app-browser'
import getClient from 'src/utils/clients/get-client'

export interface SidebarItemProps extends SidebarItemType {
  isPinned: boolean
  isStaffPage: boolean
  onClick: (item: SidebarItemType) => void
}

export function SidebarItem(props: SidebarItemProps) {
  const item = props
  const { isActive, isStaffPage, isExternal = false } = item

  const style: ButtonProps = {
    // this is effectively the row height
    height: 10,
    width: 'full',
    // transitionDuration for the width change above
    transitionDuration: 'normal',
    // this is the base variant
    variant: 'secondary',
    bgColor: 'initial',
    // remove the _focus, borderRadius, justifyContent, and padding styles
    // set by the base button variant
    _focus: {
      outline: 'initial',
    },
    _hover: {
      bgColor: isActive
        ? isStaffPage
          ? 'gray.900'
          : 'blue.50'
        : isStaffPage
          ? 'gray.800'
          : 'gray.100',
    },
    borderRadius: 'initial',
    justifyContent: 'start',
    flexShrink: 0,
    padding: 0,
    // hide the overflow, the inner width doesn't change with the parent width
    overflow: 'hidden',
    // we use the active state of a button for styling
    colorScheme: isActive ? 'blue' : undefined,
  }

  return isExternal && getClient().isAndroid ? (
    <AndroidExternalLinkButton item={item} style={style} />
  ) : (
    <WebLinkButton item={item} style={style} />
  )
}

interface LinkButtonProps {
  item: SidebarItemProps
  style: ButtonProps
}

const AndroidExternalLinkButton = ({ item, style }: LinkButtonProps) => {
  const openExternalLink = async () => {
    await inAppBrowser.open(item?.path)
  }

  return (
    <Button onClick={openExternalLink} prefetch={false} {...style} aria-label={item.ariaLabel}>
      <SidebarItemButtonCell item={item} />
    </Button>
  )
}

const WebLinkButton = ({ item, style }: LinkButtonProps) => {
  const { ariaLabel, isExternal, onClick } = item

  return (
    <Button
      as={Link}
      href={item?.path}
      prefetch={false}
      {...style}
      aria-label={ariaLabel}
      data-testid={`menu-${item.featureName}`}
      onClick={onClick ? () => onClick(item) : undefined}
      target={isExternal ? '_blank' : null}
    >
      <SidebarItemButtonCell item={item} />
    </Button>
  )
}
