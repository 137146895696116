import { bidlClient } from 'src/api/bidl/client'

const iamAccessEvaluateBaseUrl = 'api/iam/access/evaluate'

type CheckResponse = {
  all: boolean
  granted: string[]
  resultWindow: { token: string }
  type?: 'api.iam.access.evaluate.v1.response#CheckEntitySuccess'
}

type Account = {
  accountId: string /* uuid */
  tenantId: string /* uuid */
}

type Location = {
  locationId: string /* uuid */
  tenantId: string /* uuid */
}

type Tenant = {
  tenantId: string /* uuid */
}

export type LookupResourcesResponse = {
  results: {
    accounts: Account[]
    locations: Location[]
    permission: string
    root: boolean
    tenants: Tenant[]
  }[]
  resultWindow?: { token: string }
  type?: 'api.iam.access.evaluate.v1.response#LookupResourcesSuccess'
}

export const iamAccessEvaluateRequests = {
  // https://architecture.bushelops.com/bushel-interfaces/latest/#tag/api.iam.access.evaluate.v1/operation/CheckEntity
  getCheckEntity: (
    permissions: string[],
    entity: {
      account: Account
      location: Location
      tenant: {
        tenantId: string /* uuid */
      }
    },
    tenant: string
  ) =>
    bidlClient
      .post(`${iamAccessEvaluateBaseUrl}/v1/CheckResource`, {
        json: {
          permissions,
          entity,
        },
        headers: {
          'X-Bushel-Tenant': tenant,
        },
      })
      .json<CheckResponse>(),

  // https://architecture.bushelops.com/bushel-interfaces/latest/#tag/api.iam.access.evaluate.v1/operation/LookupResources
  lookupResources: (permissions: string[], tenant: string) =>
    bidlClient
      .post(`${iamAccessEvaluateBaseUrl}/v1/LookupResources`, {
        headers: {
          'X-Bushel-Tenant': tenant,
        },
        json: {
          permissions,
        },
      })
      .json<LookupResourcesResponse>(),
}
