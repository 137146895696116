import { ReactNode } from 'react'
import {
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  ModalHeader as ChakraModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  ModalHeaderProps,
} from '@chakra-ui/react'
import { ChakraModalProps } from 'src/components/designsystem'

export interface ModalProps {
  title?: string | ReactNode // TODO: Remove when full conversion to ModalNext is complete
  body: string | ReactNode // TODO: Remove when full conversion to ModalNext is complete
  footer?: ReactNode // TODO: Remove when full conversion to ModalNext is complete
  isOpen: boolean
  isCentered?: boolean
  hideCloseButton?: boolean
  onClose: () => void
  fixedHeaderAndFooter?: boolean
  motionPreset?: ChakraModalProps['motionPreset']
  size?: ChakraModalProps['size']
  my?: number
}

export function ModalNext({
  fixedHeaderAndFooter = false,
  isOpen = false,
  isCentered = true,
  hideCloseButton = false,
  onClose,
  motionPreset = 'scale',
  size = 'md',
  my = null,
  children,
}: Readonly<Omit<ModalProps, 'body' | 'title' | 'footer'> & { children: ReactNode }>) {
  return (
    <ChakraModal
      isOpen={isOpen}
      onClose={onClose}
      isCentered={isCentered}
      blockScrollOnMount={false}
      scrollBehavior={fixedHeaderAndFooter ? 'inside' : undefined}
      motionPreset={motionPreset}
      size={size}
    >
      <ModalOverlay />
      <ModalContent
        mx={[5, null, 'initial']}
        my="unset"
        mt={my ?? 'env(safe-area-inset-top)'}
        mb={my ?? 'env(safe-area-inset-bottom)'}
        overflowY="auto"
        maxH={['90vh', '90svh']}
        overscrollBehavior="contain"
      >
        {!hideCloseButton && <ModalCloseButton top={4} right={4} />}
        {children}
      </ModalContent>
    </ChakraModal>
  )
}

export function ModalHeader({
  children,
  textStyle = 'h5',
  ...props
}: Omit<ModalHeaderProps, 'textStyle'> & { textStyle?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' }) {
  return (
    <ChakraModalHeader textStyle={textStyle} data-testid="modal-header" {...props}>
      {children}
    </ChakraModalHeader>
  )
}

export function Modal({ title, body, footer, isCentered = false, ...props }: Readonly<ModalProps>) {
  return (
    <ModalNext isCentered={isCentered} {...props}>
      {title && <ModalHeader textStyle="h5">{title}</ModalHeader>}
      <ModalBody>{body}</ModalBody>
      {footer && <ModalFooter>{footer}</ModalFooter>}
    </ModalNext>
  )
}
