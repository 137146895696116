import useAggregatorConfigCheck from 'src/components/resource/useAggregatorConfigCheck'
import { AUTHZ_PERMISSIONS } from 'src/api/bidl/iam/authz-permissions'
import { useHasStaffPermission } from 'src/data/useHasStaffPermission'

export const CRM_CONFIG_KEY = 'crm'
export const PREMIUM_CRM_VERSION = '2.0.0'

export default function useHasCrmAccess() {
  const { hasPermission: hasCrmManagePermission, isLoading: isCrmManagePermissionLoading } =
    useHasStaffPermission(AUTHZ_PERMISSIONS.CUSTOMER_MANAGE)

  const {
    isLoading: isAggregatorConfigCheckLoading,
    isConfigEnabled,
    isVersionMatching: premiumVersionMatching,
  } = useAggregatorConfigCheck(CRM_CONFIG_KEY, PREMIUM_CRM_VERSION)

  const hasFullCrmAccess = hasCrmManagePermission && premiumVersionMatching

  const isLoading = isAggregatorConfigCheckLoading || isCrmManagePermissionLoading

  return {
    isLoading,
    hasCrmManagePermission,
    hasFullCrmAccess,
    hasPremiumCrm: premiumVersionMatching,
    isCrmConfigEnabled: isConfigEnabled,
  }
}
