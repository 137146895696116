import { MutableRefObject, useCallback, useEffect } from 'react'
import {
  Box,
  Flex,
  HamburgerIcon,
  HStack,
  IconButton,
  Spacer,
  useBreakpoint,
} from 'src/components/designsystem'
import { ReportIssueModal } from 'src/components/report-issue'
import { BushelWalletButtonHub } from 'src/components/hub/BushelWalletButtonHub'
import { MerchandiserBanner } from 'src/components/merch-access'
import { RightSidebar } from 'src/components/layout/right-sidebar/RightSidebar'
import { useAppData } from 'src/data'
import { ENV, useIsStaffPage } from 'src/utils'
import BrandingLogo from './sidebar/BrandingLogo'
import { NotificationHistoryHeaderButton } from 'src/components/layout/header-buttons/NotificationHistoryHeaderButton'
import AppDebugHeaderButton from 'src/components/layout/header-buttons/AppDebugHeaderButton'
import MyAccountHeaderButton from 'src/components/layout/header-buttons/MyAccountHeaderButton'
import SettingsHeaderButton from 'src/components/layout/header-buttons/SettingsHeaderButton'
import NoServerSideRendering from 'src/components/utils/NoServerSideRendering'
import create from 'zustand'
import { FLAG_KEYS, useGlobalFlag } from 'src/utils/flagr'
import { CssPixelValue } from 'src/components/designsystem/measurements/css-pixel-value'
import useMeasureInPixels from 'src/components/designsystem/measurements/user-measure-in-pixels'
import getClient from 'src/utils/clients/get-client'
import { IntercomHelpButton } from 'src/app/intercom'
import HelpAndSupportHeaderButton from 'src/components/layout/header-buttons/HelpAndSupportHeaderButton'
import { useRightSidebarStore } from 'src/components/layout/right-sidebar/right-sidebar-store'
import CustomerSearchBar, {
  useCustomerSearchBar,
} from 'src/components/layout/header-buttons/CustomerSearchBar'

export interface HeaderProps {
  sidebarTriggerRef?: MutableRefObject<HTMLButtonElement>
  isPinned: boolean
  setIsPinned: (pinned: boolean) => void
}

/** Header bar for the page, with sidebar toggle, settings icon, and avatar */
export function Header({ sidebarTriggerRef, isPinned, setIsPinned }: HeaderProps) {
  const { auth } = useAppData()
  const { isDesktop } = useBreakpoint()
  const isMerchandiserEnabled = auth.hasPermission('data_viewer') || ENV.MOCK_MERCH_MODE

  const isAuthPage = typeof window !== 'undefined' && window.location.href.indexOf('/auth/') >= 0
  const togglePinned = useCallback(() => {
    setIsPinned(!isPinned)
  }, [setIsPinned, isPinned])

  const [headerRef, { bottom }] = useMeasureInPixels()
  const setHeight = useHeaderHeightStore((state) => state.setHeight)

  useEffect(() => {
    setHeight(bottom)
  }, [setHeight, bottom])

  const isStaffFlagEnabled = useGlobalFlag(FLAG_KEYS.WEBAPP_STAFF_ROUTES)
  const isStaffPage = useIsStaffPage()
  const showBannerForStaff = isStaffFlagEnabled && auth.isStaff

  const showSettingsHeaderButton = !auth.isStaff

  const { closeRightSidebar } = useRightSidebarStore()
  const { isCustomerSearchBarEnabled } = useCustomerSearchBar()

  return (
    <Box
      ref={headerRef}
      bg="white"
      pt="calc(env(safe-area-inset-top) - 8px)"
      zIndex="above-detail-modal"
    >
      {(showBannerForStaff || isMerchandiserEnabled) && !isStaffPage && <MerchandiserBanner />}
      <Flex
        w="full"
        bgColor="white"
        borderBottom="1px"
        borderColor="gray.300"
        // On smaller screens that use a "fullscreen" modal, keep the header on top, but below a normal modal's overlay
        // zIndex={isDesktop ? undefined : 'above-detail-modal'}
        zIndex="above-detail-modal"
        flexDirection="column"
        pr={[4, null, 6, 8]}
      >
        <Flex>
          {/* menu button */}
          <Flex p={2}>
            <IconButton
              ref={sidebarTriggerRef}
              aria-label="Expand Menu"
              data-testid="menu-hamburger"
              fontSize="3xl"
              icon={<HamburgerIcon />}
              onClick={() => {
                togglePinned()
                closeRightSidebar()
              }}
              variant="menuIcon"
              isRound
              boxSize={10}
            />
          </Flex>
          {/* logo */}
          <Flex align="center" px={2} data-testid="company-logo">
            <BrandingLogo />
          </Flex>

          <Flex
            display={['none', null, 'flex']}
            flex={1}
            mx={4}
            justifyContent="center"
            alignItems="center"
          >
            <Box flex={1} maxW={550}>
              <CustomerSearchBar />
            </Box>
          </Flex>

          <Spacer display={['flex', null, 'none']} />

          <HStack spacing={2}>
            {ENV.BUSHEL_ENVIRONMENT !== 'prod' && <AppDebugHeaderButton />}

            <NoServerSideRendering>
              {getClient().isNativeApp && <NotificationHistoryHeaderButton />}
            </NoServerSideRendering>

            {auth.authenticated && !isAuthPage && (
              <BushelWalletButtonHub isExpanded={isDesktop} showBorder />
            )}

            {auth.isStaff ? (
              <IntercomHelpButton />
            ) : (
              <HelpAndSupportHeaderButton isDesktop={isDesktop} />
            )}

            {showSettingsHeaderButton ? (
              <SettingsHeaderButton />
            ) : (
              <MyAccountHeaderButton isAuthenticated={auth.authenticated} isDesktop={isDesktop} />
            )}
          </HStack>
        </Flex>
        {isCustomerSearchBarEnabled && (
          <Flex
            mb={4}
            ml={4}
            mt={2}
            flex={1}
            alignItems="center"
            justifyContent="center"
            display={['flex', null, 'none']}
          >
            <Box flex={1}>
              <CustomerSearchBar />
            </Box>
          </Flex>
        )}
        <RightSidebar />
        <ReportIssueModal />
      </Flex>
    </Box>
  )
}

interface HeaderHeightState {
  height: CssPixelValue
  setHeight: (height: CssPixelValue) => void
}

export const useHeaderHeightStore = create<HeaderHeightState>((set) => ({
  height: '0px',
  setHeight: (height: CssPixelValue) => set({ height }),
}))
